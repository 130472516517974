import { getCampaignsUsecase } from '~/usecases/campaigns/get-campaigns-usecase';

export default async function ({ store, route, app }) {
  const { params } = route;
  const { getters, dispatch } = store;
  if (params.store) {
    const storeId = getters['home/getCurrentStoreId'];

    // load parallel resources
    await Promise.all([dispatch('home/loadHalls'), dispatch('home/getBrandRooms'), getCampaignsUsecase(storeId)]);

    const brandroom = await dispatch('home/getBrandroomBySlug', params.department);
    Object.assign(app, { nuxtDepartmentLayout: brandroom ? 'mq-brandroom-layout' : 'mq-store-layout' });
  }
}

